<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-date-picker v-model="searchDate" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
      </div>
      <div class="search-box">
        用户:<el-input type="text" class="width_150" placeholder="请输入用户ID"  v-model="search.tip_uid"></el-input>
        帖子:<el-input type="text" class="width_150" placeholder="请输入帖子ID"  v-model="search.post_id"></el-input>
        楼主:<el-input type="text" class="width_150" placeholder="请输入楼主ID"  v-model="search.target_uid"></el-input>
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <div class="search">
      <span style="color:red">今日打赏数:{{today_count}} / 今日打赏金额:{{today_price}}</span>
    </div>
    <el-table :data="tableData" @selection-change="selectionChange" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="tip_uid" label="打赏用户" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="target_uid" label="楼主" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="post_id" label="帖子ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="price" label="打赏金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="remark" label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="add_time" label="打赏时间" align="center" :show-overflow-tooltip="true"></el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
  </div>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "community_tips",
  components: {
    page,
  },
  data(){
    return {
      today_count:0,
      today_price:0,
      searchDate:[],
      pager:{page:1,rows:50,total:0},
      search:{
        type:-1,
        target_uid:null,
        post_id:null,
        tip_uid:null,
      },
      tableData:[],
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    getList(){
      var params = {
        page:this.pager.page,
        row:this.pager.rows,
        post_id:this.search.post_id,
        target_uid:this.search.target_uid,
        tip_uid:this.search.tip_uid,
        begin_time:this.searchDate && this.searchDate.length > 1?this.searchDate[0]:"",
        end_time:this.searchDate && this.searchDate.length > 1?this.searchDate[1]:"",
      }
      this.$common.showLoading()
      httpClient("GetTipsLog").post(params).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          this.tableData = res.data.list
          this.today_count = res.data.today_count
          this.today_price = res.data.today_price
          if (this.pager.page == 1 ){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
</style>